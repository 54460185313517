import { useRouter } from "next/router"
import React, { useEffect } from "react"

const Redirect = ({ route }: any) => {
  const router = useRouter()

  useEffect(() => {
    router.push(route)
  }, [route])
  return <></>
}

export default Redirect
