import Redirect from "components/redirect-component/redirect"
import Head from "next/head"
import React, { ReactElement, useEffect } from "react"
import generateConfig from "configs/config"
import { Main } from "components/common/Main"
import { useTranslation } from "react-i18next"
import htmlEncode from "utils/htmlEncode"
import Loader from "components/loader/Loader"
import PageNotFound from "pages/404"
import {
  CmsPageProps,
  getInlineContent,
  getCombinedContent,
} from "services/CmsApi"
import NodeCache from "node-cache"

const config = generateConfig()
const cache = new NodeCache({ stdTTL: 60, checkperiod: 80 })

export default function CmsPage({
  title,
  content,
  missing,
  enqueuedScripts,
  enqueuedStylesheets,
  registeredStylesheets,
  registeredScripts,
  styleTags,
}: CmsPageProps): ReactElement {
  const { t } = useTranslation()

  const lastWordIndex = config.wordPressAPIUrl.lastIndexOf("/")
  const wordpressBase = config.wordPressAPIUrl.substring(0, lastWordIndex)

  const scripts = enqueuedScripts?.nodes.map((item: any) => {
    if (!item?.src?.includes("/wp-admin")) {
      return { src: item.src, id: item.handle }
    }
  })
  const registeredScript = registeredScripts?.nodes.map((item: any) => {
    if (!item?.src?.includes("/wp-admin")) {
      return { src: item.src ? `${wordpressBase}${item.src}` : "" }
    }
  })

  const styles = enqueuedStylesheets?.nodes.map((item: any) => {
    if (!item?.src?.includes("/wp-admin")) {
      return {
        href: `${item.src}${item?.version ? `?ver=${item?.version}` : ""}`,
        id: item.handle,
      }
    }
  })

  const registeredStyles = registeredStylesheets?.nodes.map((item: any) => {
    if (!item?.src?.includes("/wp-admin")) {
      return {
        href: `${item.src ? `${wordpressBase}${item.src}` : ""}`,
      }
    }
  })

  function cleanup() {
    document.querySelectorAll('[fromcms]').forEach((el) => {
      el?.parentNode?.removeChild(el)
    })
  }

  useEffect(() => {
    if (!missing) {
      scripts?.forEach((url: any) => {
        if (url) {
          const script = document.createElement("script")
          script.src = url.src
          script.id = url.id
          document.head.appendChild(script)
          script.setAttribute('fromcms', 'true')
        }
      })
      registeredScript?.forEach((url: any) => {
        if (url) {
          const script = document.createElement("script")
          script.src = url.src
          document.head.appendChild(script)
          script.setAttribute('fromcms', 'true')
        }
      })
      styles?.forEach((tag: any) => {
        if (tag) {
          const link = document.createElement("link")
          link.href = tag.href
          link.id = tag.id
          link.rel = "stylesheet"
          document.head.appendChild(link)
          link.setAttribute('fromcms', 'true')
        }
      })
      registeredStyles?.forEach((tag: any) => {
        if (tag) {
          const link = document.createElement("link")
          link.href = tag.href
          link.rel = "stylesheet"
          document.head.appendChild(link)
          link.setAttribute('fromcms', 'true')
        }
      })

      styleTags?.forEach((tag: any) => {
        if (tag) {
          const tempDiv = document.createElement("div")
          tempDiv.innerHTML = tag
          const styleTag: any = tempDiv.firstChild
          document.head.appendChild(styleTag)
          styleTag.setAttribute('fromcms', 'true')
        }
      })

      // fire this to emulate a reload of the page content
      // since these pages are CMS, a lot of plugins expect this to be called again
      window.document.dispatchEvent(new Event("DOMContentLoaded", {
        bubbles: true,
        cancelable: true
      }))
    }
    return cleanup
  }, [])

  if (missing) {
    return <Redirect route="/" />
  }

  return (
    <>
      <Head>
        <title>{`${title} | ${config?.siteName}`}</title>
        <meta
          name="description"
          content={htmlEncode(`${t("metadata.flyersDescription")}`)}
        />
        <meta
          name="keywords"
          content={htmlEncode(
            `${t("metadata.flyersKeywords")} | ${config?.siteName}`
          )}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0; user-scalable=1"
        />
      </Head>
      <Main>
        <div className="wp-block-group has-global-padding is-layout-constrained wp-block-group-is-layout-constrained">
          <h1 className="wp-block-post-title">{title}</h1>
        </div>
        <div
          className="entry-content wp-block-post-content has-global-padding is-layout-constrained wp-block-post-content-is-layout-constrained"
          dangerouslySetInnerHTML={{ __html: content || "" }}
        />
      </Main>
    </>
  )
}

// This function gets called at build time
export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' }
}

export async function getStaticProps(context: any) {
  const { slug } = context.params

  if (cache.has(slug[0])) {
    return {
      props: { missing: true },
      // 5 minutes
      revalidate: 300
    }
  }

  const [combined, styleTags] = await Promise.all([
    getCombinedContent(slug[0]),
    getInlineContent()
  ])

  if (combined.missing) {
    cache.set(slug[0], '1', 60 * 5) // 5 Min
  }

  return {
    props: { ...combined, ...styleTags },
    // 5 minutes
    revalidate: 300
  }
}
